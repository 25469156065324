APP.datepicker = function ($) {

    "use strict";

    var getDefaultTimeDiff = function (date, number, period) {
        return moment(date).add(number, period).format('YYYY-MM-DD')
    };

    var fromElement = $('#effective_date_from');

    var toElement = $('#effective_date_to');

    var datepickerOptions = {
        format: 'YYYY-MM-DD',
        time: false,
        switchOnClick: true,
        lang: APP.locale
    };

    if (APP.locale == 'fr') {
        datepickerOptions['cancelText'] = 'Annuler';
        datepickerOptions['okText'] = 'OK';
        datepickerOptions['clearText'] = 'Annuler';
        datepickerOptions['nowText'] = 'Aujourd\'hui';
    }

    var dateTimePickerOptions = {
        lang: APP.locale
    };

    var datePickerRangeOptions = {
        format: 'YYYY-MM-DD',
        time: false,
        switchOnClick: true,
        lang: APP.locale
    };

    /**
     *
     **/
    var fromElementHandler = function () {
        toElement.bootstrapMaterialDatePicker(datepickerOptions);
        fromElement.bootstrapMaterialDatePicker(datepickerOptions).on('beforeChange', function (e, date) {
            var selectedDate = date.clone();
            toElement.bootstrapMaterialDatePicker('setMinDate', selectedDate);
            toElement.bootstrapMaterialDatePicker('setDate', selectedDate.add(1, 'years'));
        });
    };

    /**
     *
     **/
    var initialize = function () {
        fromElementHandler();
        $('.datepicker').bootstrapMaterialDatePicker(datepickerOptions);
    };

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.datepicker.init();
});