APP.dynamicTabs = function ($) {

    "use strict";

    var el = '.dynamic-tabs';

    var element = $(el);

    var newid, currentTabGroup;

    var addItemButton = element.find('.add-row');

    var initialize = function () {
        addItemHandler();
        removeTabHandler();

        $(document).on('keyup blur', '[name$="][year]"], [name$="][brand]"], [name$="][model]"]', function () {
            updateTabText($(this));
        });

        //$('[name$="][model]"], [name$="][model]"], [name$="][model]"]').trigger('blur');
    };

    var updateTabText = function(_that){
        var currentContent = _that.closest('.tab-pane');
        var index = currentContent.index();
        var currentTab = element.find('.nav-tabs li').eq(index);
        var year = currentContent.find('[name$="][year]"]').val();
        var brand = currentContent.find('[name$="][brand]"]').val();
        var model = currentContent.find('[name$="][model]"]').val();
        var a = currentTab.find('a');

        a.text(year + ' ' + brand + ' ' + model);
    };

    var addItemHandler = function () {
        $(document).on('click', '.add-row', function (e) {
            e.preventDefault();
            currentTabGroup = $(this).closest(el);
            cloneTab();
            addTabContent();
        });
    };

    var removeTabHandler = function () {
        $(document).on('click', '.btn-remove-tab', function (e) {
            e.preventDefault();
            var idx = $(this).closest('.tab-pane').index();
            var current = $(this).closest(el);

            if (current.find('.nav-tabs li').length == 1) {
                return false;
            }
            current.find('.tab-pane').eq(idx).remove();
            current.find('.nav-tabs li').eq(idx).remove();
            current.find('.nav-tabs li:last-child a').trigger('click');
        });
    };

    var cloneTab = function () {
        var tabs = currentTabGroup.find('.nav-tabs li');
        var li = tabs.eq(0).clone();
        tabs.removeClass('active');
        newid = (new Date().getTime());
        tabs.closest('ul').append(li);
        var car = APP.locale == 'fr' ? 'Nouveau Véhicule' : 'New Vehicule';
        li.addClass('active').find('a').attr('href', '#_' + newid).text(car);
    };

    var addTabContent = function () {
        var tabPanes = currentTabGroup.find('.tab-pane');
        var content = tabPanes.eq(0).clone();

        cleanInputs(content);

        content.find('input, select, textarea').each(function () {
            var attr = $(this).attr('name');
            if (typeof attr === typeof undefined || attr === false) {
                return;
            }
            $(this).attr('name', $(this).attr('name').replace('\[0\]', '\[' + tabPanes.length + '\]'));
        });

        content.attr('id', '_' + newid);

        currentTabGroup.find('.tab-content').append(content);

        currentTabGroup.find('.tab-pane').removeClass('active in');

        content.addClass('active in');

        currentTabGroup.find('.nav-tabs li:last-child').addClass('active');
    };

    var cleanInputs = function (holder) {
        holder.find('input:text').val('').blur();
        holder.find('textarea').val('').blur();
        holder.find('select').val('').blur();
        //holder.find('input:checkbox').prop('checked', false).blur();
        //holder.find('input:radio').prop('checked', false).blur();
        holder.find('input[type="file"]').each(function(){
            var name = $(this).attr('name');
            $(this).replaceWith('<input type="file" name="' + name + '" />');
        });

        return holder;
    };

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.dynamicTabs.init();
});