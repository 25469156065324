APP.confirm = function ($) {

    "use strict";

    var element = '.btn-confirm';

    var i18n = {
        fr: {
            title: 'Voulez-vous continuer?',
            text: 'Les données seront supprimées définitivement.',
            confirmButtonText: 'Oui supprimer les données',
            successTitle: 'Supprimé !',
            successText: 'L\'opération à réussi'
        },
        en: {
            title: 'Are you sure?',
            text: 'You will not be able to undo this action',
            confirmButtonText: 'Yes, delete it!',
            successTitle: 'Deleted!',
            successText: 'The operation was successfull'
        }
    };

    var options = {
        title: i18n[APP.locale].title,
        text: i18n[APP.locale].text,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F55549",
        confirmButtonText: i18n[APP.locale].confirmButtonText,
        closeOnConfirm: false,
        //showLoaderOnConfirm: true
    };

    var initialize = function () {
        confirmButtonHandler();
    };

    var confirmButtonHandler = function () {
        $(document).on('click', element, function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            swal(options, function () {
                window.location.href = url;
                //swal(i18n[APP.locale].successTitle, i18n[APP.locale].successText, "success");
            });
        });
    };

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.confirm.init();
});