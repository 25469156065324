APP.ajax = {

    post: function (u, d, dt, cb) {
        dt = typeof dt !== 'undefined' ? dt : 'json';
        return $.ajax({
            url: u,
            data: d,
            type: 'POST',
            dataType: dt
        });
    },

    get: function (u, d, dt, cb) {
        dt = typeof dt !== 'undefined' ? dt : 'json';
        return $.ajax({
            url: u,
            data: d,
            type: 'GET',
            dataType: dt
        });
    },

    infos: function () {
        var infos = {};
        $.get('http://jsonip.com/', function (r) {
            infos.user_ip = r.ip;
            infos.uagent = navigator.userAgent;
            infos.referer = window.location.href;
        });
        return infos;
    }
};