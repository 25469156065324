APP.datatables = function ($) {

    "use strict";

    var selector = '.datatable';

    var element = $(selector);

    var i18n = {
        fr: {
            oAria: {
                sSortAscending: ' - appuyer sur [ENTER] pour filter par ordre ascendant',
                sSortDescending: ' - appuyer sur [ENTER] pour filter par ordre descendant'
            },
            oPaginate: {
                sFirst: 'Première page',
                sLast: 'Dernière page',
                sNext: 'Page suivante',
                sPrevious: 'Page précédente'
            },
            sEmptyTable: 'Aucune donnée disponible',
            sInfo: '<h3><span class="pull-right click-total"></span>Total de _TOTAL_ enregistrements, (_START_ à _END_)</h3>',
            sInfoEmpty: 'Aucun résultats',
            sInfoFiltered: ' - filtrées sur _MAX_ enregistrements',
            sInfoPostFix: '',
            sInfoThousands: ' ',
            sLengthMenu: 'Résultats par page _MENU_',
            sLoadingRecords: 'Un instant - en traitement...',
            sProcessing: 'Chargement',
            sSearch: 'Filtrer',
            //sUrl : '', // language file
            sZeroRecords: 'Il n\'y a aucun enregistrement'
        },
        en: {
            oAria: {
                sSortAscending: ' - click/return to sort ascending',
                sSortDescending: ' - click/return to sort descending'
            },
            oPaginate: {
                sFirst: 'First page',
                sLast: 'Last page',
                sNext: 'Next page',
                sPrevious: 'Previous page'
            },
            sEmptyTable: 'No data available in table',
            sInfo: '<h3><span class="pull-right click-total"></span>Got a total of _TOTAL_ entries to show (_START_ to _END_)</h3>',
            sInfoEmpty: 'No entries to show',
            sInfoFiltered: ' - filtering from _MAX_ records',
            sInfoPostFix: 'All records shown are derived from real information.',
            sInfoThousands: '\'',
            sLengthMenu: 'Results per page _MENU_',
            sLoadingRecords: 'Please wait - loading...',
            sProcessing: '... loading',
            sSearch: 'Search',
            //sUrl : '', // language file
            sZeroRecords: 'No records to display'
        }
    };

    var option1 = {
        iDisplayLength: 50,
        bSort: false,
        oLanguage: i18n[APP.locale],
        // add pagination at the top and bottom
        dom: '<"top"flp<"clear">>rt<"bottom"i<"clear">>',
        fnInitComplete: function () {
            styleToolbar();
        }
    };

    var styleToolbar = function () {
        $('#DataTables_Table_0_wrapper').removeClass('form-inline');

        var filter = '#DataTables_Table_0_filter';
        var length = '#DataTables_Table_0_length';

        $(length + ',' + filter).wrapAll('<div class="row">');
        $(length + ',' + filter).addClass('col-sm-6');//.insertBefore($(this).prev('.row'));

        $(length).append('<div class="form-group"/>');

        $(length).find('select').css({
            'width': '100%',
            'display': 'block',
            'padding': '2px'
        }).appendTo(length + ' .form-group');

        $(length).find('label').css('margin-bottom', '15px').prependTo(length + ' .form-group');

        $(filter).append('<div class="form-group"/>');
        $(filter).find('input')
            .attr('placeholder', $(filter).find('label').text())
            .appendTo(filter + ' .form-group');
        $(filter).find('label').html('&nbsp;');

        $('.click-total').text($('#click-total').text());
    };

    var myDataTables = function () {
        if (element.length > 0) {
            element.dataTable(option1);
        }
    };

    return {
        init: function () {
            myDataTables();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.datatables.init();
});