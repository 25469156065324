APP.fileinput = function ($) {

    "use strict";

    var initialize = function () {
        onChangeHandler();
        onFileselectHandler();
    };

    var onChangeHandler = function(){
        $(document).on('change', '.btn-file input:file', function() {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });
    };

    var onFileselectHandler = function(){
        $('.btn-file input:file').on('fileselect', function(event, numFiles, label) {
            var input = $(this).parents('.input-group').find(':text'),
                log = numFiles > 1 ? numFiles + ' files selected' : label;
            if( input.length ) {
                input.val(log);
            } else {
                if( log ) alert(log);
            }
        });
    };

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.fileinput.init();
});