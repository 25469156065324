APP.appendableRowsTable = function ($) {

    "use strict";

    var elements = $('.appendable-rows');

    var initialize = function () {
        addItemHandler();
        removeItemHandler();
    };

    /**
     * Add an item to the table
     */
    var addItemHandler = function () {
        elements.find('.add-row').on('click', function (e) {
            e.preventDefault();

            // cache
            var table = $(this).closest('table');
            var tr = table.find('tbody tr');
            var row = tr.eq(0).clone();

            // Rename each field [0] to [00], [000], [0000] to have a multidimensionnal array to save
            row.find('input, select, textarea').each(function () {
                var attr = $(this).attr('name');
                if (typeof attr === typeof undefined || attr === false) {
                    return;
                }
                $(this).attr('name', $(this).attr('name').replace('\[0\]', '\[' + tr.length + '\]'));
            });

            row = cleanInputs(row);

            table.find('tbody').append(row);

            // reinit plugins here
            APP.datepicker.init();
        });
    };

    /**
     * Remove an item from the table
     */
    var removeItemHandler = function () {
        $(document).on('click', '.remove-row', function (e) {
            e.preventDefault();
            var numberOfRows = $(this).closest('tbody').find('tr').length;
            if (numberOfRows > 1) {
                $(this).closest('tr').remove();
            }
        });
    };

    var cleanInputs = function (element) {
        element.find('input').val('').blur();
        element.find('textarea').val('').blur();
        element.find('select').val('').blur();
        element.find('input:checkbox').prop('checked', false).blur();
        element.find('input:radio').prop('checked', false).blur();

        return element;
    };

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.appendableRowsTable.init();
});