APP.select2 = function ($) {

    "use strict";

    var element = $(".select2");

    var mySelect2 = function () {
        element.select2();

        $(".select2-selection span").attr('title', '');
        /*
        $.fn.select2.amd.require(['select2/compat/matcher'], function (oldMatcher) {
            element.select2({
                matcher: oldMatcher(matchStart)
            })
        });
        */
    };

    var matchStart = function (text, term) {
        console.log(text);
        console.log(term);
        if (text.toUpperCase().indexOf(term.toUpperCase()) == 0) {
            return true;
        }
        return false;
    };

    return {
        init: function () {
            mySelect2();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.select2.init();
});