APP.mask = function ($) {

    "use strict";

    var initialize = function () {

        $('.mask-date').mask('1111-11-11');
        $('.mask-digit').mask('0#');
        $('.mask-time').mask('00:00:00');
        $('.mask-date_time').mask('0000-00-00 00:00:00');
        $('.mask-cep').mask('00000-000');
        $('.mask-phone1').mask('0000-0000');
        $('.mask-phone_with_ddd').mask('(00) 0000-0000');
        $('.mask-phone').mask('(000) 000-0000');
        $('.mask-year').mask('0000');
        $('.mask-postal_code').mask('A0A 0A0');
        $('.mask-mixed').mask('AAA 000-S0S');
        $('.mask-cpf').mask('000.000.000-00', {reverse: true});
        $('.mask-money').mask('000.000.000.000.000,00', {reverse: true});

    };

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.mask.init();
});