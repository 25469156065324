APP.ajaxSetup = function ($) {

    "use strict";

    var initialize = function () {
        ajaxsetup();
    };

    /*
    *
    * Add csrf token to all ajax request
    *
    * */
    var ajaxsetup = function(){
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
    };

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.ajaxSetup.init();
});