APP.tooltip = function ($) {

    "use strict";

    var initialize = function () {
        $('[data-toggle="tooltip"]').tooltip();
    };

    return {
        init: function () {
            initialize();
        }
    }
}(jQuery);

$(document).ready(function () {
    APP.tooltip.init();
});